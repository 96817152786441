import { MIXPANEL_API_KEY, ENV, Environments } from '../../../config';
import { except, logger } from '../../../lib/utils';

const MIXPANEL_BLACKLIST_PROPERTIES = [
  'title',
  'avatar',
  'errors',
  'account',
  'new_user',
  'chat_data',
  'confirmed',
  'updated_at',
  'calendar_api',
  'authentication_token',
  'organization',
];

export default {
  initialized: false,
  loadedInstance: null,
  getInstance: async () => {
    const { default: MixPanel } = await import('mixpanel-browser');

    return MixPanel;
  },
  initialize() {
    import('mixpanel-browser')
      .then(({ default: MixPanel }) => {
        MixPanel.init(MIXPANEL_API_KEY, {
          debug: ENV === Environments.STAGE,
          cross_site_cookie: true,
          loaded: () => {
            logger('Mixpanel has been loaded!');
          },
        });
        this.loadedInstance = MixPanel;
        this.initialized = true;
      });
  },
  formatUserInformation(user) {
    const {
      name,
      email,
      locale,
      lastname,
      created_at: createdAt,
      ...rest
    } = user;

    const properties = {
      $email: user.email,
      $first_name: user.name,
      $last_name: user.lastname,
      $created: createdAt || null,
      $last_login: new Date(),
      language: locale || 'en',
      ...except(rest, MIXPANEL_BLACKLIST_PROPERTIES),
    };

    if (user.organization_name) {
      properties.organization_name = user.organization_name;
    } else if (user.organization) {
      properties.organization_name = user.organization?.name;
    } else if (user.account) {
      properties.organization_name = user.account?.name;
    }

    if (!properties.role_alias) {
      properties.role_alias = user.type;
    }

    // Clear undefined values in properties object.
    Object.keys(properties).forEach((key) => properties[key] === undefined && delete properties[key]);

    return except(properties, MIXPANEL_BLACKLIST_PROPERTIES);
  },
  setUserInformation(user) {
    if (this.initialized) {
      const { people } = this.loadedInstance;
      people.set(this.formatUserInformation(user));
    } else {
      console.error('MIXPanel not initialized!');
    }
  },
};
