import { isAuthenticated } from './lib/utils';

export const Environments = {
  LOCAL: 'LOCAL',
  STAGE: 'STAGE',
  PRODUCTION: 'PRODUCTION',
  PREPRODUCTION: 'PREPRODUCTION',
};

export const APP_VERSION = '2.7.7'; // Optimizations v2
/**
 * When creating a build, react set the NODE_ENV to production.
 * In order to create a staging build (for testing) you can force the dev mode from here.
 * You can also, force a production mode on localhost.
 * WARNING:NOTE: SET TO FALSE WHEN BUILDING FOR PRODUCTION
 */
export const FORCED_MODE = false;
export const MODE = Environments.PRODUCTION;
const setEnvironment = () => (process.env.NODE_ENV === 'development' ? Environments.STAGE : Environments.PRODUCTION);
const forceMode = (isActive, mode) => (isActive ? Environments[mode] : setEnvironment());

/**
 * INFO: This is the variable that controls all environments in the app
 */
export const ENV = forceMode(FORCED_MODE, MODE);
/* This flag set the Unbounce landing when is true and the gatsby landing when false */
export const IFRAMED_LANDING = ENV === Environments.PRODUCTION;

export const APP_PATH = {
  [Environments.LOCAL]: 'https://localhost:8000',
  [Environments.STAGE]: 'https://staging-classrooms.kinedu.com/app',
  [Environments.PRODUCTION]: 'https://edu.kinedu.com/app',
  [Environments.PREPRODUCTION]: 'https://staging-classrooms.kinedu.com/app',
}[ENV];

export const DAP_PATH = {
  [Environments.LOCAL]: '/dap',
  [Environments.STAGE]: '/app/dap',
  [Environments.PRODUCTION]: '/app/dap',
  [Environments.PREPRODUCTION]: '/app/dap',
}[ENV];

export const OAUTH_CLIENT_ID = {
  [Environments.LOCAL]: '766607178884-3desdgibo8ltfvo30ihsf560ipqqved7.apps.googleusercontent.com',
  [Environments.STAGE]: '766607178884-8sb6576o437ki6v0n64m04nd5ant1d4k.apps.googleusercontent.com',
  [Environments.PRODUCTION]: '766607178884-6l1hq07thlgrjrcdg27fq18f1bemj6tu.apps.googleusercontent.com',
  [Environments.PREPRODUCTION]: '766607178884-6l1hq07thlgrjrcdg27fq18f1bemj6tu.apps.googleusercontent.com',
}[ENV];

export const UNBOUNCE_URL = {
  [Environments.STAGE]: 'https://edu-unbounce.kinedu.com/',
  [Environments.PRODUCTION]: 'https://edu-unbounce.kinedu.com/',
  [Environments.PREPRODUCTION]: 'https://edu-unbounce.kinedu.com/',
}[ENV];

export const windowGlobal = typeof window !== 'undefined' ? window : {
  localStorage: {
    setItem: () => {},
    getItem: () => {},
  },
};

windowGlobal.Classrooms = {
  appVersion: APP_VERSION,
  environment: ENV,
  appPath: APP_PATH,
};

export const FB_PIXEL_ID = {
  [Environments.LOCAL]: '2913815712018978',
  [Environments.STAGE]: '2913815712018978',
  [Environments.PRODUCTION]: '282642659414908',
  [Environments.PREPRODUCTION]: '282642659414908',
}[ENV];

export const GTM_ID = {
  [Environments.STAGE]: 'GTM-MWQM2F9',
  [Environments.PRODUCTION]: 'GTM-53GZ852',
  [Environments.PREPRODUCTION]: 'GTM-53GZ852',
}[ENV];

export const MIXPANEL_API_KEY = {
  [Environments.STAGE]: 'ee1a39895ddf65b1e510eb4cb5be5c04',
  [Environments.PRODUCTION]: 'b9499cc655bfce79a2f10eb14ab8f4a9',
  [Environments.PREPRODUCTION]: 'b9499cc655bfce79a2f10eb14ab8f4a9',
}[ENV];

windowGlobal.localStorage.setItem('GOOGLE_AUTH_ENABLED', 'true');

export const PROJECT_PATH = () => {
  if (windowGlobal.location) {
    return windowGlobal.location.origin;
  }
  return '';
};

if (isAuthenticated() && (ENV !== Environments.LOCAL)) {
  const appPath = `${PROJECT_PATH()}/app`;
  if (PROJECT_PATH) windowGlobal.location.assign(appPath);
}
