/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const EventReporter = require('./src/appEvents');

exports.onClientEntry = () => {
  // Initialize Event Trackers
  EventReporter.default.initialize();
};
