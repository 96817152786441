import { BehaviorSubject } from 'rxjs';
import { EventProviders, EventType, EventProvidersColors } from '../constants/events';
import { mixpanel, fbPixel, gtm } from './providers';

/**
 * Show the list of the available provider trackers
 *
 * @const array
 */

const ProviderAdapters = {
  [EventProviders.MIXPANEL]: (type, { tag, params }) =>
    mixpanel.loadedInstance.track(tag, { ...params }),
  [EventProviders.FB_PIXEL]: (type, { tag, params }) =>
    fbPixel.loadedInstance.track(tag, { ...params }),
  [EventProviders.GTM]: (type, { tag, params }) =>
    gtm.loadedInstance.dataLayer({ dataLayer: { event: tag, ...params } }),
};

/**
 * Get the original instance of the Event tracker
 * for example mixpanel would return an instance from 'mixpanel-browser';
 * @param eventProviderName
 */
export const getEventProviderInstance = (eventProviderName) => {
  const providers = {
    [EventProviders.MIXPANEL]: mixpanel.loadedInstance,
    [EventProviders.FB_PIXEL]: fbPixel.loadedInstance,
  };

  return providers[eventProviderName];
};

/**
 * The default tracker providers
 * @const array
 */
export const defaultProviders = [EventProviders.MIXPANEL];

// eslint-disable-next-line func-names
const LoggerSaver = function () {
  const eventList$ = new BehaviorSubject([]);
  const list = [];
  const saveToLogger = (event) => {
    const providers = event.providers.map((item) => ({
      name: item,
      color: EventProvidersColors[item],
    }));
    list.unshift({ ...event, providers });
    eventList$.next(list);
  };
  const getEvents = () => eventList$;

  return {
    saveToLogger,
    getEvents,
  };
};

export const loggerSaver = new LoggerSaver();

/**
 *
 * @param type Type of event e.g. View, Action.
 * @param event Contains the information of the event.
 * @param providers List of the event trackers that will dispatch the event.
 */
const report = (
  type,
  event,
  providers = defaultProviders,
) => {
  loggerSaver.saveToLogger({ ...event, providers });

  providers.forEach((provider) => ProviderAdapters[provider](type, event));
};

/**
 * The function used to create an event action
 * @const array
 */
const action = (event, providers) =>
  report(EventType.ACTION, event, providers);

/**
 * The function used to create an event view
 * @const array
 */
const view = (event, providers) =>
  report(EventType.VIEW, event, providers);

/**
 * Send the user's information to the event trackers to identify it.
 * @param user the user logged
 */
const setUserAttributes = (user) => {
  mixpanel.setUserInformation(user);
};

const EventReporter = {
  async initialize() {
    /**
     * mixpanel variable comes from 'appEvents/providers/mixpanel/index.ts'
     * in this file we do all the necessary to initialize it.
     * Do NOT confuse it with 'mixpanel-browser'.
     */
    await mixpanel.initialize();
    await fbPixel.initialize();
    await gtm.initialize();
    console.log('%cℹ️ PROVIDERS INITIALIZED', 'background: #006579; color: #c2f5ff; font-size: 11px');
  },
  action,
  view,
  report,
  setUserAttributes,
};

export default EventReporter;
