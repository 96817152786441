import { FB_PIXEL_ID, ENV, Environments } from '../../../config';
import { logger } from '../../../lib/utils';

export default {
  initialized: false,
  loadedInstance: null,
  getInstance: async () => {
    const { default: PIXEL } = await import('react-facebook-pixel');

    return PIXEL;
  },
  initialize() {
    import('react-facebook-pixel')
      .then(({ default: ReactPixel }) => {
        ReactPixel.init(FB_PIXEL_ID, null, { debug: ENV === Environments.STAGE });
        this.initialized = true;
        this.loadedInstance = ReactPixel;
        logger('Pixel Loaded!');
      });
  },
  formatUserInformation(user) {
    return {
      ...user,
    };
  },
  setUserInformation(user) {
    return user;
  },
};
