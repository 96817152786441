// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dap-jsx": () => import("./../../../src/pages/dap.jsx" /* webpackChunkName: "component---src-pages-dap-jsx" */),
  "component---src-pages-features-activity-plan-js": () => import("./../../../src/pages/features/activityPlan.js" /* webpackChunkName: "component---src-pages-features-activity-plan-js" */),
  "component---src-pages-features-calendar-js": () => import("./../../../src/pages/features/calendar.js" /* webpackChunkName: "component---src-pages-features-calendar-js" */),
  "component---src-pages-features-catalog-js": () => import("./../../../src/pages/features/catalog.js" /* webpackChunkName: "component---src-pages-features-catalog-js" */),
  "component---src-pages-features-messages-js": () => import("./../../../src/pages/features/messages.js" /* webpackChunkName: "component---src-pages-features-messages-js" */),
  "component---src-pages-features-resources-js": () => import("./../../../src/pages/features/resources.js" /* webpackChunkName: "component---src-pages-features-resources-js" */),
  "component---src-pages-for-parents-jsx": () => import("./../../../src/pages/for-parents.jsx" /* webpackChunkName: "component---src-pages-for-parents-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-unbounce-js": () => import("./../../../src/pages/index_unbounce.js" /* webpackChunkName: "component---src-pages-index-unbounce-js" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-semperaltius-jsx": () => import("./../../../src/pages/semperaltius.jsx" /* webpackChunkName: "component---src-pages-semperaltius-jsx" */),
  "component---src-pages-set-password-jsx": () => import("./../../../src/pages/set-password.jsx" /* webpackChunkName: "component---src-pages-set-password-jsx" */),
  "component---src-pages-signin-jsx": () => import("./../../../src/pages/signin.jsx" /* webpackChunkName: "component---src-pages-signin-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */)
}

