import { windowGlobal, ENV, Environments } from '../config';

const metaTranslations = {
  en: {
    title: 'Kinedu Educators',
    description: 'Find tools for teachers and parents created by experts.',
    legTitle: 'Semper Altius Online Preschool Educators | powered by Kinedu',
    legDescription: 'Clases en línea, videos y actividades con un fin pedagógico para continuar su aprendizaje y máximo desarrollo, desde la seguridad de tu casa.',
  },
  es: {
    title: 'Kinedu Educadores',
    description: 'Encuentra recursos educativos creados por expertos.',
    legTitle: 'Semper Altius Online Preschool Educators | powered by Kinedu',
    legDescription: 'Clases en línea, videos y actividades con un fin pedagógico para continuar su aprendizaje y máximo desarrollo, desde la seguridad de tu casa.' },
  pt: {
    title: 'Kinedu Educadores',
    description: 'Encontre recursos educativos criados por especialistas.',
    legTitle: 'Semper Altius Online Preschool Educators | powered by Kinedu',
    legDescription: 'Clases en línea, videos y actividades con un fin pedagógico para continuar su aprendizaje y máximo desarrollo, desde la seguridad de tu casa.' },
};

export default metaTranslations;

export const isAuthenticated = () => (
  !!windowGlobal.localStorage.getItem('auth_token')
  && !!windowGlobal.localStorage.getItem('account_token'));

export const only = (obj, keys, fallback) => {
  const newObjectValues = {};

  keys.forEach((key) => {
    if (key in obj) {
      newObjectValues[key] = obj[key] === null ? fallback || '' : obj[key];
    }
  });

  return newObjectValues;
};

export const except = (obj, keys) => {
  const newObjectValues = {};

  Object.keys(obj).forEach((key) => {
    if (!keys.includes(key)) {
      newObjectValues[key] = obj[key];
    }
  });

  return newObjectValues;
};

export const isSSR = typeof window === 'undefined';


export const logger = (...values) => {
  if (ENV === Environments.STAGE) {
    // eslint-disable-next-line no-console
    console.log(...values);

    return true;
  }

  return null;
};

export const randomString = (length = 15) =>
  [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

export const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
