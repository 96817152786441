import { GTM_ID } from '../../../config';
import { logger } from '../../../lib/utils';

export default {
  initialized: false,
  loadedInstance: null,
  getInstance: async () => {
    const { default: GTM } = await import('react-gtm-module');

    return GTM;
  },
  initialize() {
    import('react-gtm-module')
      .then(({ default: TagManager }) => {
        TagManager.initialize({
          gtmId: GTM_ID,
        });
        this.initialized = true;
        this.loadedInstance = TagManager;
        logger('GTM Loaded!');
      });
  },
  formatUserInformation(user) {
    return {
      ...user,
    };
  },
  setUserInformation(user) {
    return user;
  },
};
