export const EventType = {
  ACTION: 'action',
  VIEW: 'view',
  STATE: 'state',
};

export const EventProviders = {
  MIXPANEL: 'MIXPANEL',
  FB_PIXEL: 'FB_PIXEL',
  GTM: 'GTM',
};

export const EventProvidersColors = {
  KINEDU: '#278ECD',
  MIXPANEL: '#004fe2',
  FB_PIXEL: '#3b5998',
};

export const AppEvents = {
  FORGOT_PASSWORD: () => ({ tag: 'SPForgotPass' }),
  LOGIN_SCREEN: () => ({ tag: 'S_SPLogin' }),
  LOGIN: () => ({ tag: 'LogIn' }),
  SIGNUP_SCREEN: () => ({ tag: 'S_SPSignup' }),
  SIGNUP: () => ({ tag: 'Signup' }),
  LOG_OUT: () => ({ tag: 'LogOut' }),
  LANDING_SCROLL: (params) => ({ tag: 'SectionVisited', params }),
};
